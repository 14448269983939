<template>
  <v-main class="py-5">
    <router-view />
  </v-main>
</template>

<script>
export default {
  metaInfo() {
    return {
      titleTemplate: '%s — Majostugan',
    };
  },

  watch: {
    $route: () => {
      // this.$nextTick(() => this.$vuetify.goTo(0));
    },
  },
};
</script>
